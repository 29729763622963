import * as React from 'react';

import logo from './logo.svg';
import './App.css';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import MainPianoListing from "./pages/mainPianoListing";
import About from "./pages/about";
import { CheckoutPage } from "./pages/checkout";
import NavBar from "./Component/navbar";

import { CartDrawer } from "./context/cart.js"

function App(props) {
    // State is just a list of names for the cart
    const [cartItems, setCartItems] = React.useState(getLocalCart());

    function getLocalCart() {
	let cart = JSON.parse(localStorage.getItem("cart"));
	if (cart != null && Object.keys(cart).length === 0) {
	    cart = new Set();
	}
	else {
	    cart = new Set(cart);
	}
	return cart;
    }

    function setCartLocal(cart) {
	localStorage.setItem("cart", JSON.stringify(Array.from(cart)));
	setCartItems(cart);
    }

    function removeAllFromCart() {
	let cart = new Set();
	setCartLocal(cart);
    }

    function addToCart(name) {
	let cart = getLocalCart();
	cart.add(name);
	setCartLocal(cart);
    }

    function removeFromCart(name) {
	let cart = getLocalCart();
	if (cart.size == 0) return;
	cart.delete(name);
	setCartLocal(cart);
    }
    
    return (
	<>
	    <BrowserRouter>
		<NavBar cartItems={cartItems} removeFromCart={removeFromCart} {...props} />	    
		<Routes>
		    <Route path="/"
			   element={<MainPianoListing
					addToCart={addToCart}
					removeFromCart={removeFromCart}
					removeAllFromCart={removeAllFromCart}
				    />}
		    >
		    </Route>
		    <Route path="/about" element={<About/>}></Route>		    
		    <Route path="/checkout" element={<CheckoutPage cartItems={cartItems}/>}></Route>
		    <Route path="/cart" element={<About/>}></Route>
		</Routes>
	    </BrowserRouter>
	</>
    );
}

export default App;
