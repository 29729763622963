import * as React from 'react'
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid } from '@mui/material';

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";

import { resolveUrl } from '../utils/utils';

const stripePromise = loadStripe('pk_test_51NRgV0HzZfNw6DmE996BYNTXKInwpNuM59LH3QeBi3p3zZqpXPoPh9yOhPi16xjmEa3TPWTJzdx2QZcr0cECx4yx00JnyhS60Q');

export function CheckoutPage(props) {
    const [clientSecret, setClientSecret] = React.useState();

    React.useEffect(() =>{
	if (clientSecret == undefined) {
	    axios.get(resolveUrl("/payment_secret/"))
		 .then(response => response.data)
		 .then((data) => {
		     setClientSecret(data['client_secret']);
		 });
	}
    }, []);
    
    const options = {
	clientSecret: clientSecret,
    };

    // TODO: This will typically load twice...shouldn't be an issue, but might want to fix eventually
    if (clientSecret == undefined) {
	// Wait for it to load, should be reasonably fast
	return;
    }
    
    return (
	<Elements stripe={stripePromise} options={options}>
	    <CheckoutForm cartItems={props.cartItems}/>
	</Elements>
    );
}


export function CheckoutButton(props) {
    const navigate = useNavigate();
    function navToCheckout() {
	navigate("/checkout");
    }
    return (
	<Button variant="contained" onClick={navToCheckout}>Checkout</Button>
    );
}

export function Checkout(props) {
    return (
 	<b>
	Checkout Page
	</b>
    );
}

function ShopForPianosButton(props) {
    const navigate = useNavigate();
    function navToHome() {
	navigate("/");
    }
    return (
	<Button variant="contained" onClick={navToHome}>Shop for pianos</Button>
    );
}

export function CheckoutForm(props) {
    let piano_res = []
    const base_url = "https://mystarterpiano-public-assets.s3.us-west-2.amazonaws.com/";
    let pianos = props.cartItems;
    for (let piano_name of pianos) {
	// const piano_name = props.piano_json[i]['piano_name'];
	// const piano_price = props.piano_json[i]['price'];
	const full_url = base_url + piano_name + ".jpg";
	const elem =
	    <Box
		component="img"
		sx={{
		    height: 233,
		    width: 350,
		    maxHeight: { xs: 233, md: 167 },
		    maxWidth: { xs: 350, md: 250 },
		}}
		alt={piano_name}
		src={full_url}
	    />;
	piano_res.push(elem);
    }

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
	// We don't want to let default form submission happen here,
	// which would refresh the page.
	event.preventDefault();

	if (!stripe || !elements) {
	    // Stripe.js hasn't yet loaded.
	    // Make sure to disable form submission until Stripe.js has loaded.
	    return;
	}

	const result = await stripe.confirmPayment({
	    //`Elements` instance that was used to create the Payment Element
	    elements,
	    confirmParams: {
		return_url: "https://www.mystarterpiano.com",
	    },
	});

	if (result.error) {
	    // Show error to your customer (for example, payment details incomplete)
	    console.log(result.error.message);
	} else {
	    // Your customer will be redirected to your `return_url`. For some payment
	    // methods like iDEAL, your customer will be redirected to an intermediate
	    // site first to authorize the payment, then redirected to the `return_url`.
	}
    };

    // Nothing in cart
    if (pianos.size === 0) {
	const cart_empty =
	    <div>
		<ShoppingCartIcon style={{ color: 'primary' }} fontSize="large" />
		<br/>
		<b>Your cart is empty</b>
		<br/>
		<ShopForPianosButton/>
	    </div>;	
	return cart_empty;
    }
    
    return (
	<>
	    <Grid container alignItems="center" justifyContent="center" spacing={3}>
		<Grid item xs={3}>
		    <form onSubmit={handleSubmit}>
			<PaymentElement />
			<button>Submit</button>
		    </form>
		</Grid>
		<Grid item xs={3}>
		    {piano_res}
		</Grid>
	    </Grid>
	</>
    );
};
