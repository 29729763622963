import React from 'react';

/* import {
 *   AppBar, Button, Toolbar, Typography,
 * } from '@mui/material/Button'; */
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import AccessibleIcon from '@material-ui/icons/Accessible';
import {AddCircleOutline, Code, ExitToApp,
	FavoriteBorder, Home, VpnKey, Info,
} from '@mui/icons-material';
	      
import { CartButton, CartDrawer } from "../context/cart"

/* import CodeIcon from '@material-ui/icons/Code';
 * import ExitToAppIcon from '@material-ui/icons/ExitToApp';
 * import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
 * import HomeIcon from '@material-ui/icons/Home';
 * import VpnKeyIcon from '@material-ui/icons/VpnKey'; */

/* import {
 *   AUTH_TOKEN, LOGIN_PATH, REGISTER_PATH,
 *   ROLLOUT_TOOL_PATH, REPLAY_TOOL_PATH, UPLOAD_GAME_PATH, WELCOME_PATH,
 * } from '../utils/constants'; */
// import useStyles from '../utils/styles';
// sx={{ marginLeft: "auto" }}



export default function NavBar(props) {
  // const { classes } = useStyles(props);
  const loggedOutButtons = (
    <>
      <Button variant="contained" href={'/'}>
        Home
        {' '}
        <Home />
      </Button>
      &nbsp;
      &nbsp;
      &nbsp;
      <Button variant="contained" href={'/about'}>
        About
        {' '}
        <Info />
      </Button>
    </>
  );
    /* const loggedInButtons = (
     *   <>
     *     <Button
     *       variant="contained"
     *       href={LOGIN_PATH}
     *       onClick={() => localStorage.removeItem(AUTH_TOKEN)}
     *     >
     *       Logout
     *       {' '}
     *       <ExitToAppIcon />
     *     </Button>
     *     &nbsp;
     *     &nbsp;
     *     &nbsp;
     *     <Button variant="contained" href={WELCOME_PATH}>
     *       Home
     *       {' '}
     *       <HomeIcon />
     *     </Button>
     *     &nbsp;
     *     &nbsp;
     *     &nbsp;
     *     <Button variant="contained" href={ROLLOUT_TOOL_PATH}>
     *       Rollout Tool
     *       {' '}
     *       <AccessibleIcon />
     *     </Button>
     *     &nbsp;
     *     &nbsp;
     *     &nbsp;
     *     <Button variant="contained" href={REPLAY_TOOL_PATH}>
     *       Replay Tool
     *       {' '}
     *       <AccessibleIcon />
     *     </Button>
     *     &nbsp;
     *     &nbsp;
     *     &nbsp;
     *     <Button variant="contained" href={UPLOAD_GAME_PATH}>
     *       Upload Game
     *       {' '}
     *       <AccessibleIcon />
     *     </Button>
     *   </>
     * ); */
    // const myButtons = localStorage.getItem(AUTH_TOKEN) ? loggedInButtons : loggedOutButtons;
    const myButtons = loggedOutButtons;
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" >
            MyStarterPiano
          </Typography>
          {myButtons}
	  <div />
	  <CartButton cartItems={props.cartItems} removeFromCart={props.removeFromCart} />
        </Toolbar>
      </AppBar>
    </div>
  );
}
