export default function About() {
    return (
	<div>
	    <p>
		MyStarterPiano was formed to make pianos available at an affordable price.
		Playing piano is one of the greatest joys in life, and there's nothing better
		than having a real piano for you and your family.
	    </p>
	    <p>
		When looking for a piano, even a used piano, it will cost
		upwards of $1,500 after it's all said and done (including moving and taxes).
		This is simply too expensive for many families.
	    </p>
	    <p>
		Our pianos start as low as $499, all costs included:
		moving, initial tuning, taxes, etc.
		What you see is what you get: whatever it's listed for is the price you'll be charged.
		We also offer a $100 discount for the financially concious who are able to come
		pick up the piano themselves.
	    </p>
	    <p>
		There are many free pianos available, but it's often hard to tell
		what you're actually getting. A free piano may have broken keys, strings,
		or hammers. It may be horribly out of tune, which will require several successive
		and expensive tunings in order to get it back in tune. It may be costly
		to fix a "free" piano in order to have it in a useable condition. Additionally,
		just to move a piano often costs hundreds of dollars.
	    </p>
	    <p>
		At MyStarterPiano, we gaurantee quality in the instruments we are providing.
		We gaurantee our pianos:
		<ul>
		    <li>Have all functioning keys</li>
		    <li>Have no broken strings</li>
		    <li>Have no broken hammers</li>
		    <li>Will be in tune (no pitch raise required)</li>
		</ul>
	    </p>
	    <p>
		There are also electric pianos and keyboards on the market.
		However, these often set students up for failure if they wish to
		continue beyond a very basic level on their piano journey.
		There is something fundamentally different about playing a real piano that even
		expensive electric pianos can't mimmic! Most notably, the actions on real pianos
		are much different than their electric counterparts. In particular, cheap electric
		keyboards do not have any built-in resistance. This makes it incredibly difficult
		to transition to playing a real piano with a weighted action if you're only used
		to playing an electric keyboard. You'll also find our prices are competitive with
		mid-grade electric keyboards, but you're getting a real piano with a real action!
	    </p>
	</div>
    );    
}
