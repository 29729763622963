import * as React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Box from '@mui/material/Box';

import { CheckoutButton } from "../pages/checkout";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
	right: 7,
	top: 10,
	// border: `2px solid ${theme.palette.background.paper}`,
	padding: '0 4px',
    },
}));

export function CartButton(props) {
    const [state, setState] = React.useState({
	drawerOpen: false,
    });

    function toggleDrawer(event, reason) {
	// Leave if there's no 'reason' and drawer is open (means click was in drawer)
	if (state['drawerOpen'] && reason == null) return;
	setState({...state, 'drawerOpen': !state['drawerOpen']})
    }
    
    return (
	<StyledBadge badgeContent={props.cartItems.size} color="error" sx={{ marginLeft: "auto" }}>
	    <IconButton onClick={toggleDrawer} >
		<ShoppingCartIcon style={{ color: 'white' }} />
		<CartDrawer
		    isOpen={state['drawerOpen']}
		    closeHook={toggleDrawer}
		    cartItems={props.cartItems}
		    removeFromCart={props.removeFromCart}
		/>
	    </IconButton>
	</StyledBadge>
    );
}

function RemoveFromCartButton(props) {
    
    function removeFromCart() {
	props.removeFromCart(props.piano_name);
    }
    
    const button =
	<Button variant="contained" onClick={removeFromCart}>
	    Remove item
	</Button>;
    return button;
}

export function CartDrawer(props) {
    
    const base_url = "https://mystarterpiano-public-assets.s3.us-west-2.amazonaws.com/";
    let drawer_display = []
    // localStorage.setItem( 'SelectedOption', option );
    /* const saved = localStorage.getItem( 'piano_cart' );
     * const piano_names = JSON.parse(piano_names) */
    for (let item of props.cartItems) {
	const piano_name = item;
	// const piano_price = props.piano_json[i]['price'];
	const full_url = base_url + piano_name + ".jpg";
	const elem =
	    <Box
		sx={{		
		    display: 'flex',
		     flexDirection: { xs: 'column', md: 'row' },
		     alignItems: 'center',
		     bgcolor: 'background.paper',
		     overflow: 'hidden',
		     borderRadius: '12px',
		     boxShadow: 1,
		     fontWeight: 'bold',
	    }}>
	<Box
		    component="img"
		    sx={{
			       height: 233,
			       width: 350,
			       maxHeight: { xs: 233, md: 167 },
			       maxWidth: { xs: 350, md: 250 },
		    }}
		    alt={piano_name}
		    src={full_url}
	/>
	{piano_name}	
	</Box>;
	const button = <RemoveFromCartButton
			   piano_name={piano_name}
			   removeFromCart={props.removeFromCart}
		       />;
	const breaks = <><br/><br/></>;
	drawer_display.push(elem);
	drawer_display.push(button);
	drawer_display.push(breaks);
    }

    if (props.cartItems.size === 0) {
	drawer_display =
	    <Box
		sx={{		
		    display: 'flex',
		     flexDirection: { xs: 'column', md: 'row' },
		     alignItems: 'center',
		     bgcolor: 'background.paper',
		     overflow: 'hidden',
		     borderRadius: '12px',
		     boxShadow: 'none',
		     fontWeight: 'bold',
		}}>
		<Box
		    sx={{
			height: 233,
			width: 350,
			maxHeight: { xs: 233, md: 167 },
			maxWidth: { xs: 350, md: 250 },
			alignItems: 'center',
			boxShadow: 'none',
			'.MuiOutlinedInput-notchedOutline': { border: 0 } ,
		    }}
		>
		    <div>
			<ShoppingCartIcon style={{ color: 'primary' }} fontSize="large" />
			<br/>
			<b>Your cart is empty</b>
		    </div>
		</Box>
	    </Box>;
    }
    else {	
	drawer_display.push(<CheckoutButton/>);
    }

    return (
	<Drawer
	    anchor='right'
	    open={props.isOpen}
	    onClose={props.closeHook}
	>
	    {drawer_display}
        </Drawer>
    );
}

function toggleOpenCartDrawer() {
    console.log("Test")
    return (
	// <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
	<Drawer anchor="right" >
	    <ul><li>Test</li></ul>
	    <AddShoppingCartIcon />
        </Drawer>
    );
}
