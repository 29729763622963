export function resolveUrl(end_url) {
    // Gives back localhost if in dev, otherwise gives back main domain
    let base_url;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	base_url = "http://localhost:8000";
    }
    else {
	base_url = "https://www.mystarterpiano.com";
    }
    return base_url + end_url;
}
