import React, { Component } from "react";
import axios from "axios";

import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { Button } from '@mui/material';

import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

import { resolveUrl } from '../utils/utils';

function BuyNowButton(props) {

    const navigate = useNavigate();
    
    function addToCartAndRedirectToCheckout() {
	props.removeAllFromCart();
	props.addToCart(props.piano_name);
	navigate("/checkout");
    }
    
    return (
	<Button
	    variant="contained"	
	    onClick={addToCartAndRedirectToCheckout}
	>
	    Buy Now
	</Button>
	);
}

function MoreInfoButton(props) {
    return <><Button variant="contained">More Info</Button></>;
}

function AddToCartButton(props) {

    function addToCartWithName() {
	props.addToCart(props.piano_name)
    }
    
    return (
	<>
	    <Button
		variant="contained"
		onClick={addToCartWithName}
		cartItems={props.cartItems}
	    >
		Add to cart
	    </Button>
	</>
	);
}

function MainPianoDisplay(props) {
    let res = [];
    const base_url = "https://mystarterpiano-public-assets.s3.us-west-2.amazonaws.com/";
    for (var i = 0; i < props.piano_json.length; i++) {
	const piano_name = props.piano_json[i]['piano_name'];
	const piano_price = props.piano_json[i]['price'];
	const full_url = base_url + piano_name + ".jpg";
	const elem =	    
	    <Box
		sx={{		
		    display: 'flex',
		     flexDirection: { xs: 'column', md: 'row' },
		     alignItems: 'center',
		     bgcolor: 'background.paper',
		     overflow: 'hidden',
		     borderRadius: '12px',
		     boxShadow: 1,
		     fontWeight: 'bold',
	    }}>
		<Box
		    component="img"
		    sx={{
			       height: 233,
			       width: 350,
			       maxHeight: { xs: 233, md: 167 },
			       maxWidth: { xs: 350, md: 250 },
		    }}
		    alt={piano_name}
		    src={full_url}
		/>
		<Box
		    sx={{
		       display: 'flex',
		       flexDirection: 'column',
		       alignItems: { xs: 'center', md: 'flex-start' },
		       m: 3,
		       minWidth: { md: 350 },
		    }}
		>
		    <Box component="span" sx={{ fontSize: 16, mt: 1 }}>
	                {piano_name}
			<br/>
			<br/>
			<MoreInfoButton />	                
			<br/>
			<br/>
			<BuyNowButton piano_name={piano_name} {...props} />
			<br/>
			<br/>
			<AddToCartButton
			    addToCart={props.addToCart}
			    cartItems={props.cartItems}
			    piano_name={piano_name}
			/>
		    </Box>
		    <Box
			sx={{
			   mt: 1.5,
			   p: 0.5,
			   backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
			   borderRadius: '5px',
			   color: 'primary.main',
			   fontWeight: 'medium',
			   display: 'flex',
			   fontSize: 12,
			   alignItems: 'center',
			   '& svg': {
			   fontSize: 21,
			   mr: 0.5,
			   },
			}}
		    >
		    </Box>
		    <Box component="span" sx={{ color: 'red', fontSize: 22 }}>
			${piano_price}
		    </Box>
		</Box>
	    </Box>;
	const with_br =
	    <>{elem}<br/><br/></>;
	/* <h4 key={i}>{props.piano_json[i]['piano_name']}</h4>
	   <img src={full_url} alt={props.piano_json[i]['piano_name']} /> */
	res.push( with_br );
    }
    return res;
}

export default class MainPianoListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            books:[],
        };
        this.loadPianos = this.loadPianos.bind(this);
    }

    // Copied this code from a starter template, hence the warning
    componentWillMount() {
        this.loadPianos();
    }

    async loadPianos()
    {
	const promise = await axios.get(resolveUrl("/book/"));
        const status = promise.status;
        if(status===200)
        {
            const data = promise.data.data;
            this.setState({books:data});
        }
    }

    render() {
        return(
            <div>
                <h1>Pianos</h1>
		<MainPianoDisplay
		    piano_json={this.state.books}
		    addToCart={this.props.addToCart}
		    removeFromCart={this.props.removeFromCart}
		    cartItems={this.props.cartItems}
		    removeAllFromCart={this.props.removeAllFromCart}
		/>
            </div>
        )
    }
}
// {this.state.books.map((value,index)=>{return <h4 key={index}>{value['piano_name']}</h4>})}
